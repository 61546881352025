<template>
  <v-dialog v-model="dialog" persistent :width="retornaLargura()">
    <v-form lazy-validation ref="form" class="d-flex">
      <v-card class="d-flex flex-column elevation-0 rounded-0 card-anexar">
        <v-card-title class="text-h5 title-card">
          Criar Anexo
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              abrirVariaveis
                ? (abrirVariaveis = false)
                : (abrirVariaveis = true)
            "
          >
            Variáveis
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-text-field
            label="Nome do arquivo"
            required
            outlined
            autofocus
            type="tel"
            class="select"
            ref="nome_arquivo"
            v-model="camposAnexar.nome_arquivo"
            @focus="mudaCampoSelecionado('nome_arquivo')"
            :rules="[() => !!camposAnexar.nome_arquivo || 'Campo obrigatório']"
          ></v-text-field>
          <div
            v-if="preVisualizarMensagem(camposAnexar.nome_arquivo)"
            v-html="preVisualizarMensagem(camposAnexar.nome_arquivo)"
            class="ml-pre-visualizacao mb-4"
            :class="{
              'pdf-after-text': tipoArquivoSelecionado == 'application/pdf',
            }"
          ></div>
          <v-text-field
            label="URL de download"
            required
            outlined
            ref="url_download"
            type="tel"
            v-model="camposAnexar.url_download"
            class="select"
            @focus="mudaCampoSelecionado('url_download')"
            :rules="[() => !!camposAnexar.url_download || 'Campo obrigatório']"
          ></v-text-field>
          <div
            v-if="preVisualizarMensagem(camposAnexar.url_download)"
            v-html="preVisualizarMensagem(camposAnexar.url_download)"
            class="ml-pre-visualizacao mb-4"
          ></div>
          <v-row align="baseline">
            <v-col cols="9">
              <v-select
                :items="tipoArquivo"
                v-model="tipoArquivoSelecionado"
                label="Tipo do arquivo"
                no-data-text="Nenhum tipo cadastrado!"
                outlined
                class="select"
                @focus="mudaCampoSelecionado(null)"
                :rules="[() => !!tipoArquivoSelecionado || 'Campo obrigatório']"
              ></v-select>
            </v-col>
            <v-col class="d-flex">
              <v-checkbox
                hide-details
                v-model="camposAnexar.obrigatorio"
                label="Obrigatório"
                class="mt-0 pt-0"
              ></v-checkbox>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="gray"
                    class="ml-1"
                    v-bind="attrs"
                    v-on="on"
                    small
                    >mdi-help-circle</v-icon
                  >
                </template>
                <span v-if="camposAnexar.obrigatorio"
                  >O email NÃO será enviado caso o anexo esteja
                  indisponível</span
                >
                <span v-if="!camposAnexar.obrigatorio"
                  >O email será enviado mesmo que o anexo esteja
                  indisponível</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-divider></v-divider>

        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn color="primary" text @click="fecharDialog()"> FECHAR </v-btn>
            <v-btn
              color="primary"
              @click="salvarAnexoAoTemplate()"
              :loading="salvandoAnexo"
            >
              SALVAR
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
      <ListaVariaveis
        :abrirVariaveis="abrirVariaveis"
        @concatenaVariavel="concatenaVariavel"
        @fecharVariaveis="abrirVariaveis = false"
      />
    </v-form>
  </v-dialog>
</template>

<script>
import ListaVariaveis from "../../reguas/components/ListaVariaveis.vue";
import variavelMensagemService from "@/services/variavel-mensagem";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ListaVariaveis,
  },
  data: () => ({
    templateEmail: {},
    abrirVariaveis: false,
    dialog: false,
    tipoArquivo: [
      {
        text: "Application/pdf",
        value: "application/pdf",
      },
    ],
    campoSelecionado: null,
    camposAnexar: {
      nome_arquivo: "",
      url_download: "",
    },
    tipoArquivoSelecionado: "application/pdf",

    salvandoAnexo: false,
  }),
  methods: {
    ...mapActions("emails", {
      salvarAnexo: "salvarAnexo",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("variavelRegua", { pegarVariaveis: "pegar" }),
    /** Retorna a mensagem com as variáveis substituídas pelos exemplos. */
    preVisualizarMensagem: variavelMensagemService.preVisualizarMensagem,
    async open(templateEmail) {
      try {
        await this.pegarVariaveis({
          tipo_regua: templateEmail.tipo_regua,
          empresa_id: this.empresaSelecionada.id,
        });
      } catch (error) {
        this.showSnackBar({ text: "Falha ao buscar as variáveis" });
        console.error(error);
      }
      this.dialog = true;
      this.templateEmail = templateEmail;
    },
    fecharDialog() {
      this.limpaCampos();
      this.dialog = false;
    },
    retornaLargura() {
      if (!this.abrirVariaveis) {
        return "650";
      } else {
        return "1000";
      }
    },
    mudaCampoSelecionado(campo) {
      this.campoSelecionado = campo;
    },
    concatenaVariavel(variavel) {
      if (!this.campoSelecionado) return;
      const posicaoCursor =
        this.$refs[this.campoSelecionado]?.$refs.input.selectionStart;
      const stringParaArray = (
        this.camposAnexar[this.campoSelecionado] || ""
      ).split("");
      stringParaArray.splice(posicaoCursor, 0, `{{${variavel.nome}}}`);
      this.camposAnexar[this.campoSelecionado] = stringParaArray.join("");
      this.$refs[this.campoSelecionado]?.$refs.input.focus();
    },
    limpaCampos() {
      this.camposAnexar.nome_arquivo = "";
      this.camposAnexar.url_download = "";
      this.tipoArquivoSelecionado = null;
      this.abrirVariaveis = false;
      this.$refs.form.resetValidation();
      this.mudaCampoSelecionado(null);
    },
    async salvarAnexoAoTemplate() {
      try {
        const anexo = await this.salvarAnexo({
          ...this.camposAnexar,
          template_email_id: this.templateEmail.id,
          content_type: this.tipoArquivoSelecionado,
        });
        this.showSnackBar({ text: "Anexo criado com sucesso!" });
        this.fecharDialog();
      } catch (error) {
        this.showSnackBar({ text: "Falha ao criar o anexo!" });
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
};
</script>

<style scoped lang="scss">
.card-anexar {
  width: 650px;
}
</style>
