<template>
  <div v-html="template"></div>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    to.meta.layout = "BlankLayout";
    next();
  },
  created() {
    const templateGuardado = sessionStorage.getItem("template");
    if (templateGuardado) {
      this.template = JSON.parse(templateGuardado);
    }
  },
  data: () => {
    return {
      template: null,
    };
  },
};
</script>

<style></style>
