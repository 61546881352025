export function formatDateTimeToUTC3(date) {
  try {
    const timezoneOffset = new Date(date).getTimezoneOffset();
    const data = new Date(date);

    data.setMinutes(data.getMinutes() - timezoneOffset);

    return data.toLocaleDateString("pt-BR", {
      timeZone: "America/Sao_Paulo",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } catch (e) {
    console.warn(e);
    return "--";
  }
}
