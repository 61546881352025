<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title class="text-h5"> Editar renderização </v-card-title>
        <v-card-text>
          <div style="height: 300px">
            <vue-monaco-editor
              @mount="handleMount"
              ref="monacoEditor"
              :line="1"
              language="json"
              v-model="json"
              :options="codeEditorOptions"
            ></vue-monaco-editor>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="decision = false">
            Voltar
          </v-btn>
          <v-btn color="primary" @click="decision = true"> Renderizar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      decision: null,
      editor: null,
      json: "{}",
      codeEditorOptions: {
        formatOnType: true,
        overviewRulerLanes: 0,
        overviewRulerBorder: false,
        minimap: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    async handleMount(editor) {
      this.editor = editor;
    },
    async open(vars) {
      this.dialog = true;
      this.json = JSON.stringify(vars || {}, null, 2);
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.decision == false) {
            resolve(false);
            this.dialog = false;
            this.decision = null;
            clearInterval(interval);
          }
          if (this.decision !== null) {
            resolve(JSON.parse(this.json));
            this.dialog = false;
            this.decision = null;
            clearInterval(interval);
          }
        }, 100);
      });
    },
  },
};
</script>

<style lang="scss"></style>
